import PropTypes from 'prop-types'
import React from 'react'
import pic01 from '../images/pic01.png'
import pic02 from '../images/pic02.png'
import pic03 from '../images/pic03.jpg'


<meta name="description" content="this is the portfolio of yunicorn" />

class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="hello"
          className={`${this.props.article === 'hello' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
        <h2 className="major">hello</h2>
          <span className="image main">
            <img src={pic01} alt="" />
          </span>
          <p>
            Yuni (she/they) is your friendly neighborhood compliance and security person, 
            and an aspiring engineer. A recent transplant from the sunny shores of California to 
            the outskirts of Philadelphia, Yuni wholeheartedly welcomes regional food recommendations. 
            In their spare time, they like to meet neighborhood cats, 
            read books on specific bits of medical history, figure out how to make recipes vegetarian,
            and ruminate on how to write in third person without sounding like a doofus (with limited success).
          </p>
          {close}
        </article>

        <article
          id="work"
          className={`${this.props.article === 'work' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Work</h2>
          <span className="image main">
            <img src={pic02} alt="" />
          </span>
          <p>
            <h3>Certifications</h3>
            Certified Information Security Auditor<br />
            CompTIA Security+<br />
            AWS Certified Cloud Practitioner<br />
          </p>
          <p>
            <h3>Regulatory Frameworks</h3>
            SOC 1/2/3 + HIPAA compliance + implementation, 
            NIST 800-53 implementation, 
            ISO 27001 implementation, 
            FedRamp mapping, SOX IT general controls
          </p>
          <p>
            <h3>Soft Skills</h3>
            Project management, self-starter, client communications, work ethic, 
            leadership/training, can make most recipes vegetarian, good with cats
          </p>
          <p>
          <h3>Computer Languages</h3>
            SQL, JavaScript, HTML, CSS, React, Linux
          </p>
          <p>
          <h3>Human Languages</h3>
            English, Korean
          </p>
          {close}
        </article>

<article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Contact</h2>
          Feel free to reach out to talk about both professional opportunities or your favorite vegan recipes!<br /><br />
          <ul className="icons">
            <li>
              <a
                href="https://www.linkedin.com/in/helloyuni/"
                className="icon fa-linkedin-square"
              >
                <span className="label">LinkedIn</span>
              </a>
            </li>
            <li>
              <a
                href="mailto:yuni.pdf@gmail.com?subject=hi yuni!"
                className="icon fa-envelope"
              >
                <span className="label">Email</span>
              </a>
            </li>
          </ul>
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
